.buttonSection {
  position: fixed;
  top: 0;
  left: 0;
  height: 2.5rem;
  width: 100vw;
  padding: 0.1rem;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-content: center;
  background: var(--sapphire);
}

.buttons {
  padding-right: 1rem;
  display: flex;
  align-content: center;
}

.button {
  margin-right: 0.1rem;
  padding: 0.3rem;
}

.hide {
  display: none;
}

.select {
  appearance: none;
  font-size: 1rem;
  height: 2.25rem;
  width: 12rem;
  padding-left: 1rem;
}

.select + div {
  display: none;
}

.fetchInfo {
  color: var(--white);
  padding-right: 1rem;
  font-size: 1rem;
  line-height: 2.3rem;
}

.countdown {
  display: flex;
  flex-direction: column;
  /*font-size: smaller;*/
  color: var(--white);
  line-height: 1em;
  justify-content: space-evenly;
  padding-left: 1em;
}

@media screen and (max-width: 599px) {
  .info {
    visibility: hidden;
  }
}

.buttonSectionIE11 {
  position: fixed;
  top: 0;
  left: 0;
  height: 2.5rem;
  width: 100vw;
  padding: 0.1rem;
  z-index: 1;
  display: flex;
  justify-content: space-between;
  align-content: center;
  background: #305a85;
}
